var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { icon: _vm.$route.meta.icon, title: _vm.formatedTitle },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "mx-1",
                                  attrs: {
                                    color: "primary",
                                    icon: "",
                                    small: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      _vm.showSearch = true
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("search")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v(" Pesquisar Ambientes ")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "mx-1",
                                  attrs: {
                                    disabled: !_vm.ambience || !_vm.ambience.id,
                                    color: "primary",
                                    icon: "",
                                    small: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.addEvent()
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-calendar-plus")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v(" Novo Agendamento ")])]
              ),
              _c("v-divider", { attrs: { vertical: "", inset: "" } }),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  ref: "btnShowList",
                                  staticClass: "ma-1",
                                  attrs: {
                                    icon: "",
                                    dark: "",
                                    large: "",
                                    color: "primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.drawer = !_vm.drawer
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", [_vm._v("mdi-format-list-checkbox")])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", [
                    _vm.drawer
                      ? _c("span", [_vm._v("Ocultar lista de ambientes")])
                      : _c("span", [_vm._v("Exibir lista de ambientes")])
                  ])
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("emc-loader-progress", { attrs: { show: _vm.progress } }),
      _c(
        "v-container",
        { staticClass: "pa-0 ma-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "fill-height" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: _vm.drawer ? 9 : 12 } },
                [
                  _c(
                    "v-sheet",
                    { attrs: { height: "64" } },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-4",
                              attrs: { outlined: "", color: "grey darken-2" },
                              on: { click: _vm.setToday }
                            },
                            [_vm._v(" Hoje ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                fab: "",
                                text: "",
                                small: "",
                                color: "grey darken-2"
                              },
                              on: { click: _vm.prev }
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(" mdi-chevron-left ")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                fab: "",
                                text: "",
                                small: "",
                                color: "grey darken-2"
                              },
                              on: { click: _vm.next }
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(" mdi-chevron-right ")
                              ])
                            ],
                            1
                          ),
                          _vm.renderComponentTitle
                            ? _c("v-toolbar-title", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$refs.calendar
                                        ? _vm.$refs.calendar.title
                                        : _vm.titleDate
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _c("v-spacer"),
                          _c(
                            "v-menu",
                            {
                              attrs: { bottom: "", right: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                outlined: "",
                                                color: "grey darken-2"
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.typeToLabel[_vm.type])
                                            )
                                          ]),
                                          _c(
                                            "v-icon",
                                            { attrs: { right: "" } },
                                            [_vm._v(" mdi-menu-down ")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          _vm.type = "day"
                                        }
                                      }
                                    },
                                    [_c("v-list-item-title", [_vm._v("Dia")])],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          _vm.type = "week"
                                        }
                                      }
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Semana")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          _vm.type = "month"
                                        }
                                      }
                                    },
                                    [_c("v-list-item-title", [_vm._v("Mês")])],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "emc-util-scroll",
                    { attrs: { maxHeight: "450px" } },
                    [
                      _c(
                        "v-sheet",
                        { staticClass: "pa-1 ma-1" },
                        [
                          _vm.renderComponent
                            ? _c("v-calendar", {
                                ref: "calendar",
                                attrs: {
                                  color: "primary",
                                  events: _vm.events,
                                  "event-color": _vm.getEventColor,
                                  type: _vm.type,
                                  "interval-count": 17,
                                  "first-interval": 7,
                                  "event-ripple": false,
                                  start: _vm.start
                                },
                                on: {
                                  "click:event": _vm.showEvent,
                                  "click:more": _vm.viewDay,
                                  "click:date": _vm.viewDay,
                                  change: _vm.getEvents,
                                  "mousedown:event": _vm.startDrag,
                                  "mousedown:time": _vm.startTime,
                                  "mousemove:time": _vm.mouseMove,
                                  "mouseup:time": _vm.endDrag
                                },
                                nativeOn: {
                                  mouseleave: function($event) {
                                    return _vm.cancelDrag($event)
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "event",
                                      fn: function(ref) {
                                        var event = ref.event
                                        var timed = ref.timed
                                        var eventSummary = ref.eventSummary
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "v-event-draggable"
                                            },
                                            [
                                              _c(
                                                { render: eventSummary },
                                                { tag: "component" }
                                              )
                                            ],
                                            1
                                          ),
                                          _vm.allowDragDrop(event) && timed
                                            ? _c("div", {
                                                staticClass:
                                                  "v-event-drag-bottom",
                                                on: {
                                                  mousedown: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.extendBottom(
                                                      event
                                                    )
                                                  }
                                                }
                                              })
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2333073350
                                ),
                                model: {
                                  value: _vm.focus,
                                  callback: function($$v) {
                                    _vm.focus = $$v
                                  },
                                  expression: "focus"
                                }
                              })
                            : _vm._e(),
                          _c(
                            "v-menu",
                            {
                              ref: "menu",
                              attrs: {
                                "close-on-content-click": false,
                                activator: _vm.selectedElement,
                                "offset-x": ""
                              },
                              model: {
                                value: _vm.selectedOpen,
                                callback: function($$v) {
                                  _vm.selectedOpen = $$v
                                },
                                expression: "selectedOpen"
                              }
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  attrs: {
                                    color: "grey lighten-4",
                                    "min-width": "350px",
                                    flat: ""
                                  }
                                },
                                [
                                  _c(
                                    "v-toolbar",
                                    { attrs: { color: "indigo", dark: "" } },
                                    [
                                      _vm.selectedEvent
                                        ? _c("v-toolbar-title", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.selectedEvent.name
                                              )
                                            }
                                          })
                                        : _vm._e(),
                                      _c("v-spacer"),
                                      _vm.allowEditForm
                                        ? _c(
                                            "span",
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                      icon: ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        $event.preventDefault()
                                                                        return _vm.openNew()
                                                                      }
                                                                    }
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      small: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-pencil"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    3634389458
                                                  )
                                                },
                                                [_c("span", [_vm._v("Editar")])]
                                              ),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                      icon: ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        $event.preventDefault()
                                                                        _vm.showDelete = true
                                                                      }
                                                                    }
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      small: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-delete-outline"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    3115136937
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Excluir")
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: { icon: "" },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              $event.preventDefault()
                                                              return _vm.closeEvent()
                                                            }
                                                          }
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("mdi-close")
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [_c("span", [_vm._v("Fechar")])]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.selectedEvent
                                    ? _c(
                                        "v-card-text",
                                        [
                                          _vm.selectedEvent.hour_start &&
                                          _vm.selectedEvent.hour_end
                                            ? _c("span", [
                                                _vm.selectedEvent.start_date ==
                                                _vm.selectedEvent.end_date
                                                  ? _c("strong", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f("date")(
                                                              _vm.selectedEvent
                                                                .start_date
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ])
                                                  : _c("strong", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f("date")(
                                                              _vm.selectedEvent
                                                                .start_date
                                                            )
                                                          ) +
                                                          " - " +
                                                          _vm._s(
                                                            _vm._f("date")(
                                                              _vm.selectedEvent
                                                                .end_date
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]),
                                                _vm._v(" | "),
                                                _c("strong", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.selectedEvent.hour_start.substring(
                                                          0,
                                                          5
                                                        )
                                                      ) +
                                                      " - " +
                                                      _vm._s(
                                                        _vm.selectedEvent.hour_end.substring(
                                                          0,
                                                          5
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              ])
                                            : _vm._e(),
                                          _c("v-divider", {
                                            staticClass: "mt-1"
                                          }),
                                          _vm.selectedEvent.type
                                            ? _c(
                                                "small",
                                                { staticClass: "mt-1" },
                                                [
                                                  _c("strong", [
                                                    _vm._v(
                                                      "Tipo de Agendamento: "
                                                    )
                                                  ]),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.selectedEvent.type
                                                        .name
                                                    ) + " "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.selectedEvent.days &&
                                          _vm.selectedEvent.days.length > 0
                                            ? _c("small", [
                                                _c("br"),
                                                _c("strong", [
                                                  _vm._v("Recorrência: ")
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.selectedEvent.days
                                                        .map(function(i) {
                                                          return i.name
                                                        })
                                                        .join(", ")
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm.selectedEvent
                                            .allocation_type_id == 3 &&
                                          _vm.selectedEvent.organizer
                                            ? _c("small", [
                                                _c("br"),
                                                _c("strong", [
                                                  _vm._v("Organizador: ")
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.selectedEvent
                                                        .organizer
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm.selectedEvent
                                            .allocation_type_id != 3 &&
                                          _vm.selectedEvent.userOrganizer
                                            ? _c("small", [
                                                _c("br"),
                                                _c("strong", [
                                                  _vm._v("Organizador: ")
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.selectedEvent
                                                        .userOrganizer.name
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm.selectedEvent.details
                                            ? _c("span", [
                                                _c("br"),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.selectedEvent.details
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm.selectedEvent.resources &&
                                          _vm.selectedEvent.resources.length > 0
                                            ? _c("small", [
                                                _c("br"),
                                                _c("strong", [
                                                  _vm._v("Recursos: ")
                                                ]),
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.selectedEvent.resources
                                                      .map(function(i) {
                                                        return i.resource.name
                                                      })
                                                      .join(", ")
                                                  ) + " "
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm.selectedEvent.user
                                            ? _c("small", [
                                                _c("br"),
                                                _c("strong", [
                                                  _vm._v("Usuário: ")
                                                ]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.selectedEvent.user
                                                        .name
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.drawer,
                      expression: "drawer"
                    }
                  ],
                  attrs: { cols: "12", sm: "3" }
                },
                [
                  _c(
                    "div",
                    [
                      _c("v-text-field", {
                        staticClass: "pa-1 ",
                        attrs: {
                          "append-icon": "mdi-magnify",
                          label: "Pesquisar",
                          "single-line": "",
                          "hide-details": "",
                          clearable: "",
                          dense: ""
                        },
                        model: {
                          value: _vm.searchAmbience,
                          callback: function($$v) {
                            _vm.searchAmbience = $$v
                          },
                          expression: "searchAmbience"
                        }
                      }),
                      _c(
                        "emc-util-scroll",
                        { attrs: { maxHeight: "450px" } },
                        [
                          _c("sys-ambience-tree-view", {
                            attrs: { search: _vm.searchAmbience },
                            on: {
                              select: function($event) {
                                return _vm.selectAmbience($event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("sys-ambience-allocation-calendar", {
        attrs: {
          ambienceId: _vm.ambience.id,
          model: _vm.eventSelectedOpen,
          show: _vm.selectedOpenNew
        },
        on: {
          onCompleted: function($event) {
            return _vm.getData()
          },
          "update:show": function($event) {
            _vm.selectedOpenNew = $event
          }
        }
      }),
      _c("sys-ambience-allocation-search", {
        attrs: { show: _vm.showSearch },
        on: {
          "update:show": function($event) {
            _vm.showSearch = $event
          },
          select: function($event) {
            return _vm.selectAmbienceSearch($event)
          }
        }
      }),
      _c("emc-alert-modal", {
        attrs: {
          title: "Exclusão",
          text: "Deseja excluir o agendamento?",
          show: _vm.showDelete,
          disabled: _vm.loadingDelete
        },
        on: {
          "update:show": function($event) {
            _vm.showDelete = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      disabled: _vm.loadingDelete,
                      loading: _vm.loadingDelete,
                      color: "error"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteItem()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-snackbar", {
        attrs: {
          show: !!_vm.message.length,
          message: _vm.message,
          color: _vm.error ? "error" : "success"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }